.Home {
  display: flex;
  justify-content: space-between;
}
.left-h {
  padding: 2rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  gap: 2rem;
  flex-direction: column;
}
.right-h {
  flex: 1;
  position: relative;
  background-color: rgba(97, 240, 97, 0.363);
  width: 100%;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
}
.moving {
  margin-top: 4rem;
  background-color: #343d42;
  border-radius: 4rem;
  width: fit-content;
  padding: 20px 13px;
  text-transform: uppercase;
  color: white;
  position: relative;
  align-items: center;
  justify-content: flex-start;
}
.moving > span {
  z-index: 2;
  position: relative;
}
.moving > div {
  position: absolute;
  background-color: rgb(93, 95, 91);
  width: 5rem;
  height: 80%;
  left: 8px;
  top: 0.4rem;
  border-radius: 3rem;
  z-index: 1;
}
.text-name {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 4.5rem;
  font-weight: bold;
  color: white;
  text-overflow: inherit;
}
.text-def {
  font-size: 1rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 50%;
  color: white;
}
.data {
  display: flex;
  gap: 2rem;
}
.data > div {
  display: flex;
  flex-direction: column;
}
.data > div > span:nth-of-type(1) {
  color: white;
  font-size: 2rem;
}
.data > div > span:nth-of-type(2) {
  color: rgb(177, 177, 177);
  text-transform: uppercase;
  font-size: 1rem;
}

/* .buttons{
    display:flex;
    gap:1rem;
} */
.btns {
  display: flex;
  gap: 1rem;
  font-weight: normal;
}
.btns > :nth-child(1) {
  color: white;
  background-color: rgba(0, 0, 0, 0.705);
  width: 8rem;
}
.btns > :nth-child(2) {
  color: white;
  background-color: transparent;
  width: 8rem;
  border: 2px solid rgba(138, 202, 138, 0.705);
}
.foto {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: rgba(44, 44, 44, 0.781);
  width: fit-content;
  padding: 1rem;
  align-items: start;
  border-radius: 5px;
  position: absolute;
  right: 5rem;
  top: 3rem;
}
.foto > span:nth-child(2) {
  color: rgb(231, 231, 231);
  font-size: 1rem;
}
.foto > img {
  width: 2rem;
}
.right-h > img {
  position: relative;
  margin-top: 14.1rem;
  border-radius: 10rem;
  width: 26.3rem;
  right: 0.1rem;
}

@media screen and (max-width: 768px) {
  .home {
    flex-direction: column;
  }
  .home blur {
    width: 10rem;
  }
 .moving{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale (0.8);
 }
 .text-name{
    font-size:xx-large;
    align-items: center;
    justify-content: center;
 }
 .data{
    justify-content: center;
    flex-direction: column;
    transform: scale (.8);

 }
 .right-h{
    position: relative;
    background:none;
 }
 .right-h>img{
    position: relative;
    width:10rem;
    left:-3rem;
    top:4rem;
 }

}

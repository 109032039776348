.proyects{
    display: flex;
    flex-direction: column;
    gap:2rem;
    padding:0 2rem;
}
.header{
    text-transform: uppercase;
    display: flex;
    gap:5rem;
    font-weight: bold;
    font-size:3rem;
    justify-content: center;
    color:white;
    font-style: italic;
}
.proyect-view{
    display: flex;
    gap:1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background-color:gray;
    padding: 2rem;
    gap:1rem;
    color:white;
    justify-content: space-between;
}
.category:hover{
    background-color: rgba(17, 70, 15, 0.63);
    cursor: pointer;
}
.category>:nth-child(1){
    width:2rem;
    height:2rem;
    fill:white;

}
.category>:nth-child(2){
   font-size: 1rem;
   font-weight: bold;
    
}.category>:nth-child(2){
    font-size: 0.9rem;
   line-height:20px;
     
 }
 .explore{
    align-items: center;
    gap:2rem;
    display: flex;
    
 }
.arrow{
    width:1rem;

}
@media screen and (max-width: 768px) {
    .header{
        flex-direction: column;
        gap: 1rem;
        font-size: large;
        align-items: center;
        justify-content:center;
        margin-top:2rem;
    }
    .proyect-view{
        flex-direction: column;

    }
}
.header-top{
    display:flex;
    justify-content:space-between;
}
.logo{
    width:4rem;
    height:3rem;
}
.header-menu{
    list-style:none;
    display:flex;
    gap: 2rem;
    color:white;
}
.header-menu>li{
    cursor: pointer;
}
.header-menu>li:hover{
    cursor: pointer;
    color: black
}
@media screen and (max-width: 768px){
    .header-top> :nth-child(2){
        position:fixed;
        right:1rem;
        z-index:99;
    }
    .header-menu{
      
        flex-direction: column;
        background-color: rgba(44, 44, 44, 0.781);
        padding:2rem
    }

 }
.footer{
    position:relative;
}
.footer>hr{
    border:1px solid lightgray;

}
.links{
    padding:  1rem; 
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
     justify-content:center;
     height:10rem;
}
.social{

    display: flex;
    gap: 5rem;

}
.social>img{

    width: 2rem;
    height: 2rem;
    cursor: pointer;

}
.logo>img{
    width:5rem;

}
.email{
    color: white;
}